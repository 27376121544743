import { memo, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import "./ContactContainer.css";

const ContactContainer = memo(
  ({
    coordinates,
    frame1Position,
    frame1Width,
    frame1Top,
    frame1Right,
    frame1Left,
    onLogoLaspiImageClick,
    onQUEMSOMOSTextClick,
    onACREDITAESTextClick,
    onSOLUESSERVIOSClick,
  }) => {
    const barraSuperiorStyle = useMemo(() => {
      return {
        position: frame1Position,
        width: frame1Width,
        top: frame1Top,
        right: frame1Right,
        left: frame1Left,
      };
    }, [frame1Position, frame1Width, frame1Top, frame1Right, frame1Left]);

    const cONTATOStyle = useMemo(() => {
      return {
        cursor: "pointer",
      };
    });

    const navigate = useNavigate();
    const onCONTATOTextClick = useCallback(() => {
      navigate("/",
        {
          state: {
            scroll: true,
          }
        }
      );
    }, []);

    return (
      <div className="barra-superior" style={barraSuperiorStyle}>
        <div className="barra-superior1">
          <div className="logo-laspi-parent">
            <img
              className="logo-laspi-icon"
              alt=""
              src={coordinates}
              onClick={onLogoLaspiImageClick}
            />
            <b className="quem-somos2" onClick={onQUEMSOMOSTextClick}>
              QUEM SOMOS
            </b>
            <b className="acreditaes2" onClick={onACREDITAESTextClick}>
              ACREDITAÇÕES
            </b>
            <b
              className="solues-servios17"
              onClick={onSOLUESSERVIOSClick}
            >{`SOLUÇÕES & SERVIÇOS `}</b>
            <b
              className="contato1"
              onClick={onCONTATOTextClick}
              style={cONTATOStyle}
            >
              CONTATO
            </b>
          </div>
        </div>
      </div>
    );
  }
);

export default ContactContainer;
