import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ContactContainer from "../components/ContactContainer";
import "./BibliotecasCriptogrficas.css";
import Footer from "../components/Footer";

const BibliotecasCriptogrficas = () => {
  const navigate = useNavigate();

  const onLogoLaspiImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onQUEMSOMOSTextClick = useCallback(() => {
    navigate("/quem-somos");
  }, [navigate]);

  const onACREDITAESTextClick = useCallback(() => {
    navigate("/acreditacoes");
  }, [navigate]);

  const onSOLUESSERVIOSClick = useCallback(() => {
    navigate("/solucoes-e-servicos");
  }, [navigate]);

  const onManuaisDeCondutasClick = useCallback(() => {
    window.open(
      "https://www.gov.br/iti/pt-br/assuntos/legislacao/manuais-icp-brasil/manuais-de-condutas-tecnicas-mcts/manuais-de-condutas-tecnicas-mct-s"
    );
  }, []);

  const onClickVoltar = useCallback(() => {
    navigate("/solucoes-e-servicos",
    {
      state: {
        opcao: "LEA",
      }
    })
  }, [navigate])

  const onSoluesServios1Click = useCallback(() => {
    navigate("/solucoes-e-servicos");
  }, [navigate]);

  return (
    <div className="bibliotecas-criptogrficas1">
      <Footer />
      <div className="voltar-group">
        <b className="voltar1" onClick={onClickVoltar}>{`< Voltar`}</b>
        <div className="leitora-de-cartes-container">
          <p className="bibliotecas-criptogrficas2">
            <b>
              <span style={{fontSize: 'var(--font-size-13xl)'}}>Bibliotecas Criptográficas</span>
            </b>
          </p>
          <p className="blank-line">
            <span>
              <b>&nbsp;</b>
            </span>
          </p>
          <p className="cartes-criptogrficos2">
            <span>
              <span>
              Nossos ensaios são realizados seguindo rigorosamente as
                exigências explícitas no Manual de Condutas Técnicas 8 - Volume
                I e II - emitidos pelo ITI no âmbito ICP-Brasil, para garantir a
                precisão e a confiabilidade dos resultados obtidos.
              </span>
            </span>
          </p>
          <p className="blank-line">
            <span>
              <b>&nbsp;</b>
            </span>
          </p>
          <p className="cartes-criptogrficos2">
            <span>
              <span>
              Se sua empresa busca obter a homologação de seus sistemas e
                equipamentos de certificação digital junto ao ITI, entre em
                contato conosco e descubra como podemos ajudar.<br></br><br></br> Somos credenciados pelo Instituto Nacional de Tecnologia da
                Informação como Laboratório de Ensaios e Auditoria – LEA, com
                amplas condições de planejar, analisar, avaliar, e emitir
                relatórios que embasam a tomada de decisão por parte do ITI
                quanto à homologação de Sistemas e Equipamentos de Certificação
                Digital padrão ICP-Brasil. Nossos serviços são essenciais para as empresas que desejam
                obter a homologação de seus sistemas e equipamentos de
                certificação digital junto ao ITI. Com nossa capacidade técnica
                e expertise, fornecemos relatórios de alta qualidade que ajudam
                nossos clientes a atenderem aos requisitos necessários e, assim,
                obterem a homologação de seus produtos.
              </span>
            </span>
          </p>
        </div>
        <div
          className="manuais-de-condutas"
          onClick={onManuaisDeCondutasClick}
        >
          Manuais de Condutas Técnicas emitidos pelo ITI
        </div>
      </div>
      <ContactContainer
        coordinates="/logolaspi1@2x.png"
        frame1Position="absolute"
        frame1Width="calc(100% - 39.83px)"
        frame1Top="20px"
        frame1Right="19.83px"
        frame1Left="20px"
        propCursor="unset"
        onLogoLaspiImageClick={onLogoLaspiImageClick}
        onQUEMSOMOSTextClick={onQUEMSOMOSTextClick}
        onACREDITAESTextClick={onACREDITAESTextClick}
        onSOLUESSERVIOSClick={onSOLUESSERVIOSClick}
      />
      <div className="barra-de-navegao">
        <div className="barra-de-navegao-child" />
        <div className="voc-est-aqui-container">
          <span>
            <span className="voc-est-aqui2">Você está aqui:</span>
            <span className="span5">{` `}</span>
          </span>
          <span className="span5">
            <span>/</span>
          </span>
        </div>
        <div className="solues-servios-container">
          <span className="barra-span">{`/  `}</span>
          <span className="bibliotecas-criptogrficas3">
            Bibliotecas Criptográficas
          </span>
        </div>
        <div
          className="solues-servios2"
          onClick={onSoluesServios1Click}
        >{`Soluções & Serviços`}</div>
      </div>
    </div>
  );
};

export default BibliotecasCriptogrficas;
