import styled from 'styled-components';

export const Box = styled.div`
padding: 25px 15px;
background: #006396;
position: absolute;
bottom: 0;
width: 100%;

@media (max-width: 1000px) {
	padding: 12px 10px;
}
`;

export const Container = styled.div`
	margin-left: auto;
	margin-right: auto;
	max-width: 70%;

@media (min-width: 1368px) {
	max-width: 50%;
	margin-left: auto;
}
`

export const Column = styled.div`
display: flex;
flex-direction: column;

text-align: center;
padding: 0 50px;

`;

export const Row = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));


@media (max-width: 1000px) {
	grid-template-columns: repeat(auto-fill,
						minmax(200px, 1fr));
}
`;

export const Image = styled.img`
width: 28px;
height: 28px;
max-width: 70%;
`;

export const Text = styled.p`

color: #fff;
font-size: 13.5px;
margin-top: 8px;
margin-left: -50px;

padding-right: 40px;
`;
