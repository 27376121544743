import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ContactContainer from "../components/ContactContainer";
import "./PginaAcreditaes.css";
import Footer from "../components/Footer";

const PginaAcreditaes = () => {
  const navigate = useNavigate();

  const onLogoLaspiImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onQUEMSOMOSTextClick = useCallback(() => {
    navigate("/quem-somos");
  }, [navigate]);

  const onACREDITAESTextClick = useCallback(() => {
    navigate("/acreditacoes");
  }, [navigate]);

  const onSOLUESSERVIOSClick = useCallback(() => {
    navigate("/solucoes-e-servicos");
  }, [navigate]);

  const onPginaInicialTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="pgina-acreditaes">
      <img className="laspi-2-icon1" alt="" src="/laspi-1@2x.png" />
      <div className="acreditaes-certificados-c-parent">
        <div className="leitora-de-cartes-container">
          <p className="acreditaes-certificados-c">
            <b>{`Acreditações, Certificados & Credenciamentos`}</b>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">&nbsp;</p>
          <p className="laboratrio-acreditado-pela">
            Laboratório Acreditado pela CGCRE/INMETRO, CRL 1227
          </p>
          <br />
          <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL1227.pdf" 
          target="_blank" title="Read PDF">Acesse nosso Escopo de Acreditação – ABNT NBR ISO/IEC 17025</a>
          <br />
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>
                <br></br>
                Através do desenvolvimento e da manutenção de um rigoroso
                Sistema de Gestão, disciplinado pela norma ABNT NBR ISO/IEC
                17025, assumimos a responsabilidade legal como Laboratório
                Acreditado pela CGCRE/INMETRO, CRL 1227, para avaliação técnica
                de diferentes equipamentos, instrumentos e softwares.
              </span>
            </span>
          </p>
          <p className="blank-line">
            <span>
              <b>&nbsp;</b>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>Destaca-se o seguinte escopo de atuação:</span>
            </span>
          </p>
          <p className="blank-line">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="equipamentos-de-certificao">
            Equipamentos de Certificação Digital padrão ICP-Brasil
          </p>
          <p className="atravs-do-desenvolvimento-e-d">{`- Cartões Criptográficos – ITI Manual de Condutas Técnicas 1; `}</p>
          <p className="atravs-do-desenvolvimento-e-d">{`- Leitora de Cartões Inteligentes – ITI Manual de Condutas Técnicas 2; `}</p>
          <p className="atravs-do-desenvolvimento-e-d">{`- Tokens Criptográficos – ITI Manual de Condutas Técnicas 3; `}</p>
          <p className="atravs-do-desenvolvimento-e-d">{`- Módulos de Segurança Criptográfica – ITI Manual de Condutas Técnicas 7. `}</p>
        </div>
        <div className="registradores-eletrnicos-de-container1">
          <p className="atravs-do-desenvolvimento-e-d">&nbsp;</p>
          <p className="atravs-do-desenvolvimento-e-d">&nbsp;</p>
          <p className="equipamentos-de-certificao">
            Registradores Eletrônicos de Ponto
          </p>
          <p className="ensaios-eltricos-magnticos">
            <span>
              <span>
                - Ensaios elétricos, magnéticos e de software – Portaria Inmetro
                n.° 4 de 2022
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>{`   `}</span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span className="medidores-eletrnicos-de">
              Medidores Eletrônicos de Energia Elétrica
            </span>
            <span>
              <span>{` `}</span>
            </span>
          </p>
          <p className="ensaios-eltricos-magnticos">
            <span>
              <span>
                - Avaliação de software de Medidores Eletrônicos de Energia
                Elétrica – Portaria Inmetro n.° 221 de 2022
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="equipamentos-de-certificao">
            Medidores Eletrônicos de Água Potável Fria e Água Quente
          </p>
          <p className="ensaios-eltricos-magnticos">
            <span>
              <span>
                - Avaliação de software de Medidores Eletrônicos de Água Potável
                Fria e Água Quente – Portaria Inmetro n.° 155 de 2022
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span className="medidores-eletrnicos-de">
              Bombas Medidoras de Combustíveis Líquidos
            </span>
            <span>
              <span>{` `}</span>
            </span>
          </p>
          <p className="ensaios-eltricos-magnticos">
            <span>
              <span>
                - Avaliação de software de Bombas Medidoras de Combustíveis
                Líquidos – Portaria Inmetro n.º 227 de 2022
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span className="medidores-eletrnicos-de">
              Medidores de Velocidade de Veículos Automotores
            </span>
            <span>
              <span>{` `}</span>
            </span>
          </p>
          <p className="ensaios-eltricos-magnticos">
            <span>
              <span>
                - Avaliação de software de Medidores de Velocidade de Veículos
                Automotores – Portaria Inmetro n.º 158 de 2022
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span className="medidores-eletrnicos-de">
              Instrumento de Pesagem Automático de Veículos Rodoviários em
              Movimento
            </span>
            <span>
              <span>{` `}</span>
            </span>
          </p>
          <p className="ensaios-eltricos-magnticos">
            <span>
              <span>
                - Avaliação de software de Instrumento de Pesagem Automático de
                Veículos Rodoviários em Movimento – Portaria Inmetro n.º 19 de
                2022
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="laboratrio-acreditado-pela">
            Laboratório de Ensaios e Auditoria (LEA) pelo ITI
          </p>
          <p className="blank-line">
            <span>
              <b>&nbsp;</b>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>
                Somos credenciados pelo Instituto Nacional de Tecnologia da Informação, nos termos da Resolução ITI - ICP Brasil n.° 130 de 19/03/2021 e n.° 200 de 29/04/2021
                , como Laboratório de Ensaios e Auditoria – LEA, com
                amplas condições de planejar, analisar, avaliar, e emitir laudos
                de conformidade que embasam a tomada de decisão por parte do ITI
                quanto a homologação de Sistemas e Equipamentos de Certificação
                Digital padrão ICP-Brasil.
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span className="sistemas-e-equipamentos">
                Sistemas e Equipamentos de Certificação Digital padrão
                ICP-Brasil;
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span className="sistemas-e-equipamentos">
                Softwares de Assinatura – ITI Manual de Condutas Técnicas 4;
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span className="sistemas-e-equipamentos">
                Softwares de Autenticação – ITI Manual de Condutas Técnicas 5;
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span className="sistemas-e-equipamentos">
                Softwares de Sigilo – ITI Manual de Condutas Técnicas 6;
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span className="sistemas-e-equipamentos">
                Bibliotecas Criptográficas – ITI Manual de Condutas Técnicas 8;
              </span>
            </span>
          </p>
          <p className="atravs-do-desenvolvimento-e-d">
            <span>
              <span className="sistemas-e-equipamentos">
                Sistemas de Carimbo de tempo – ITI Manual de Condutas Técnicas
                10.
              </span>
            </span>
          </p>
        </div>
      </div>
      <div className="barra-de-navegao">
        <div className="barra-de-navegao-child" />
        <div className="voc-est-aqui-container">
          <span>
            <span className="sistemas-e-equipamentos">Você está aqui:</span>
            <span className="span33">{` `}</span>
          </span>
          <span className="span33">
            <span>/</span>
          </span>
        </div>
        <div className="acreditaes">
          <span className="barra-span">{`/  `}</span>
          <span className="acreditaes1">Acreditações</span>
        </div>
        <div className="pgina-inicial1" onClick={onPginaInicialTextClick}>
          Página Inicial
        </div>
      </div>
      <ContactContainer
        coordinates="/logolaspi1@2x.png"
        frame1Position="absolute"
        frame1Width="calc(100% - 39.83px)"
        frame1Top="20px"
        frame1Right="19.83px"
        frame1Left="20px"
        onLogoLaspiImageClick={onLogoLaspiImageClick}
        onQUEMSOMOSTextClick={onQUEMSOMOSTextClick}
        onACREDITAESTextClick={onACREDITAESTextClick}
        onSOLUESSERVIOSClick={onSOLUESSERVIOSClick}
      />
      <Footer />
    </div>
    
  );
};

export default PginaAcreditaes;
