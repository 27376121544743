import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";

import PginaInicial from "./pages/PginaInicial";
import SoluesServios from "./pages/SoluesServios";
import SistemasDeCarimboDeTempo from "./pages/SistemasDeCarimboDeTempo";
import BibliotecasCriptogrficas from "./pages/BibliotecasCriptogrficas";
import SoftwaresDeSigilo from "./pages/SoftwaresDeSigilo";
import SoftwaresDeAutenticao from "./pages/SoftwaresDeAutenticao";
import SoftwaresDeAssinatura from "./pages/SoftwaresDeAssinatura";
import MedidorDeEnergia from "./pages/MedidorDeEnergia";
import MedidorDeGua from "./pages/MedidorDeGua";
import InstrumentoDePesagemRodovi from "./pages/InstrumentoDePesagemRodovi";
import MedidoresDeVelocidadeAutomo from "./pages/MedidoresDeVelocidadeAutomo";
import BombasDeCombustvel from "./pages/BombasDeCombustvel";
import REP from "./pages/REP";
import HSM from "./pages/HSM";
import TokensCriptogrficos from "./pages/TokensCriptogrficos";
import CartesCriptogrficos from "./pages/CartesCriptogrficos";
import Leitoras from "./pages/Leitoras";
import PginaAcreditaes from "./pages/PginaAcreditaes";
import PginaQuemSomos from "./pages/PginaQuemSomos";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/" || "/home":
        title = "LASPI UFRJ";
        metaDescription = "";
        break;
      case "/solucoes-e-servicos":
        title = "Soluções & Serviços";
        metaDescription = "";
        break;
      case "/sistemas-de-carimbo-de-tempo":
        title = "Carimbo de Tempo";
        metaDescription = "";
        break;
      case "/bibliotecas-criptograficas":
        title = "Bibliotecas Criptográficas";
        metaDescription = "";
        break;
      case "/softwares-de-sigilo":
        title = "Softwares de Sigilo";
        metaDescription = "";
        break;
      case "/softwares-de-autenticacao":
        title = "Softwares de Autenticação";
        metaDescription = "";
        break;
      case "/softwares-de-assinatura":
        title = "Softwares de Assinatura";
        metaDescription = "";
        break;
      case "/medidores-de-energia":
        title = "Medidores de Energia";
        metaDescription = "";
        break;
      case "/medidores-de-agua":
        title = "Medidores de Água";
        metaDescription = "";
        break;
      case "/instrumentos-de-pesagem-rodoviario":
        title = "Pesagem Rodoviária Automática";
        metaDescription = "";
        break;
      case "/medidores-de-velocidade-automotivo":
        title = "Medidores de Velocidade";
        metaDescription = "";
        break;
      case "/bombas-de-combustivel":
        title = "Bombas de Combustível";
        metaDescription = "";
        break;
      case "/rep":
        title = "Relógio Eletrônico de Ponto";
        metaDescription = "";
        break;
      case "/hsm":
        title = "Módulo de Segurança Criptográfico";
        metaDescription = "";
        break;
      case "/tokens-criptograficos":
        title = "Tokens Criptográficos";
        metaDescription = "";
        break;
      case "/cartoes-criptograficos":
        title = "Cartões Criptográficos";
        metaDescription = "";
        break;
      case "/leitoras":
        title = "Leitoras de Cartão";
        metaDescription = "";
        break;
      case "/acreditacoes":
        title = "Acreditações";
        metaDescription = "";
        break;
      case "/quem-somos":
        title = "Quem Somos";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<PginaInicial />} />
      <Route path="/home" element={<PginaInicial/>}/>

      <Route path="/solucoes-e-servicos" element={<SoluesServios />} />
      <Route
        path="/sistemas-de-carimbo-de-tempo"
        element={<SistemasDeCarimboDeTempo />}
      />
      <Route
        path="/bibliotecas-criptograficas"
        element={<BibliotecasCriptogrficas />}
      />
      <Route path="/softwares-de-sigilo" element={<SoftwaresDeSigilo />} />
      <Route
        path="/softwares-de-autenticacao"
        element={<SoftwaresDeAutenticao />}
      />
      <Route
        path="/softwares-de-assinatura"
        element={<SoftwaresDeAssinatura />}
      />
      <Route path="/medidores-de-energia" element={<MedidorDeEnergia />} />
      <Route path="/medidores-de-agua" element={<MedidorDeGua />} />
      <Route
        path="/instrumentos-de-pesagem-rodoviario"
        element={<InstrumentoDePesagemRodovi />}
      />
      <Route
        path="/medidores-de-velocidade-automotivo"
        element={<MedidoresDeVelocidadeAutomo />}
      />
      <Route path="/bombas-de-combustivel" element={<BombasDeCombustvel />} />
      <Route path="/rep" element={<REP />} />
      <Route path="/hsm" element={<HSM />} />
      <Route path="/tokens-criptograficos" element={<TokensCriptogrficos />} />
      <Route path="/cartoes-criptograficos" element={<CartesCriptogrficos />} />
      <Route path="/leitoras" element={<Leitoras />} />
      <Route path="/acreditacoes" element={<PginaAcreditaes />} />
      <Route path="/quem-somos" element={<PginaQuemSomos />} />
    </Routes>
  );
}
export default App;
