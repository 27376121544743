import { memo, useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Typewriter from "react-ts-typewriter";
import "./InitialImageContainer.css";

import banner1 from "../banners/banner1.jpg";
import banner2 from "../banners/banner2.jpg";
import banner3 from "../banners/banner3.jpg";

const InitialImageContainer = memo(() => {
  const [cursor1On, setCursor1On] = useState(true)
  const [cursor2On, setCursor2On] = useState(true)
  const [showText2, setShowText2] = useState(false)
  const navigate = useNavigate();

  const [banner, setBanner] = useState(0)
  
  const goToSOMOS = useCallback(() => {
    navigate("/quem-somos");
  }, [navigate]);

  const startText2 = () => {
    setCursor1On(false)
    setShowText2(true)
  }

  const endText = () => [
    setCursor1On(false),
  ]

  const Banners = [
    { 
      backgroundImage: `url(${banner1})`,
      transition: "transform 0.7s ease-out 0.5s"
    },
    {
      backgroundImage: `url(${banner2})`,
      transition: "transform 0.7s ease-out 0.5s"
    },
    {
      backgroundImage: `url(${banner3})`,
      transition: "transform 3s ease-out 0.5s"
    }
  ]

  const changeBackgroundImage = () => {
    setBanner((banner + 1) % 3)
  }

  setTimeout(changeBackgroundImage, 15000)

  return (
    <div className="imagem-inicial" style={Banners[banner]}>
      <div style={Banners[(banner+1)%3]}></div>
      <div className="button">
        <div className="button-simple-animations">
          <div className="base" onClick={goToSOMOS}>
            <div className="curtain" />
            <img className="icon-placeholder" alt="" />
            <div className="text">SAIBA MAIS SOBRE NÓS</div>
          </div>
        </div>
      </div>
      <div className="title">
        <div className="h1">
          <Typewriter 
            text='CONHEÇA O LASPI'
            speed={60}
            cursor={cursor1On}
            onFinished={startText2}
          />
        </div>
        {showText2 ? <div className="h2">
          <Typewriter 
            text='LABORATÓRIO DA UFRJ ESPECIALIZADO EM SEGURANÇA DE SOFTWARE'
            speed={20}
            cursor={cursor2On}
            onFinished={endText}
          />
        </div>
        :
          null
        }
        
      </div>
    </div>
  );
});
export default InitialImageContainer;
