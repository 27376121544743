import { memo, useRef, useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./Slider3.css";

const Slider2 = memo(() => {
  const navigate = useNavigate();
  const carousel = useRef();
  const [width, setWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth + 45);
  }, [])

  const goLeft = useCallback(() => {
    setScrollLeft(0)
  })
  
  const goRight = useCallback(() => {
    setScrollLeft(1)
  })

  // rotas
  const onClickAssinatura = useCallback(() => {
    navigate("/softwares-de-assinatura")
  }, [navigate])
  const onClickCarimbos = useCallback(() => {
    navigate("/sistemas-de-carimbo-de-tempo")
  }, [navigate])
  const onClickBibliotecas = useCallback(() => {
    navigate("/bibliotecas-criptograficas")
  }, [navigate])
  const onClickSigilo = useCallback(() => {
    navigate("/softwares-de-sigilo")
  }, [navigate])
  const onClickAutenticacao = useCallback(() => {
    navigate("/softwares-de-autenticacao")
  }, [navigate])

  return (
    <motion.div id="lea" className="slider2" ref={carousel}>
        <motion.div className="carousel1"
        dragConstraints={{ left: -width, right: 0 }}
        dragElastic={0.1}
        dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
        style={{ transform: scrollLeft ?  'translateX(-'+width+'px) translateY(0px) translateZ(0px)': 'translateX(0px) translateY(0px) translateZ(0px)', transition: 'transform 0.5s ease 0s'}}
        >
          <div className="softwares-de-assinatura-wrapper" onClick={onClickAssinatura}>
            <div className="texto-cards-escopo">SOFTWARES DE ASSINATURA</div>
          </div>
          <div className="softwares-de-autenticao-wrapper" onClick={onClickAutenticacao}>
            <div className="texto-cards-escopo">
              SOFTWARES DE AUTENTICAÇÃO
            </div>
          </div>
          <div className="softwares-de-sigilo-wrapper" onClick={onClickSigilo}>
            <div className="texto-cards-escopo">SOFTWARES DE SIGILO</div>
          </div>
          <div className="bibliotecas-criptogrficas-wrapper" onClick={onClickBibliotecas}>
            <div className="texto-cards-escopo">
              BIBLIOTECAS CRIPTOGRÁFICAS
            </div>
          </div>
          <div className="sistemas-de-carimbo-de-tempo-wrapper" onClick={onClickCarimbos}>
            <div className="texto-cards-escopo">
              SISTEMAS DE CARIMBO DE TEMPO
            </div>
          </div>
        </motion.div>
        {scrollLeft === 0 ? <button className="button-right" onClick={goRight}>↪</button>
        : <button className="button-left" onClick={goLeft}>↩</button>}
        {scrollLeft === 0 ? <img className="dots-icon" src="/dots.svg"/> 
        : <img className="dots-icon" src="/dots1.svg"/>}
      </motion.div>
  );
});

export default Slider2;
