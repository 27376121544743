import React, { useCallback } from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Image,
Text
} from "./FooterStyles";

const Footer = () => {

const goToLinkedin = useCallback(() => {
    window.open("https://www.linkedin.com/company/laspi-ufrj/");
}, []);

return (
	<Box>
	<Container>
		<Row>
		<Column>
            <Image src="/phone.svg" ></Image>
		</Column>
        <Text>(21) 3938-0204 </Text>


        <Column>
            <Image src="/email.svg" ></Image>
        </Column>
        <Text>contato@laspi.ufrj.br</Text>
        
        <Column onClick={goToLinkedin} style={{cursor:'pointer'}}>
            <Image src="/linkedin.svg" ></Image>
        </Column>
        <Text onClick={goToLinkedin} style={{cursor:'pointer'}}>LASPI - UFRJ</Text>
		</Row>
	</Container>
	</Box>
);
};
export default Footer;
