import { memo, useCallback } from "react";
import "./FaleConoscoContainer.css";
import { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker, InfoBox} from "@react-google-maps/api";

const FaleConoscoContainer = memo(() => {
  const {isLoaded} = useLoadScript({googleMapsApiKey: "AIzaSyCYJyu1wDcLJnxIRTJkNnJqVZhl94xC8zM"});
  const laspiCords = useMemo(() => ({lat: -22.8619323, lng: -43.228863}));

  const onClickMap = useCallback(() => {
    window.open('https://www.google.com/maps/place/LASPI+-+Laborat%C3%B3rio+de+Aplica%C3%A7%C3%B5es+Tecnol%C3%B3gicas+para+o+Setor+Produtivo+Industrial/@-22.8619323,-43.2310517,17z/data=!3m1!4b1!4m6!3m5!1s0x9979cfccb436e9:0xe151bee006908f19!8m2!3d-22.8619323!4d-43.228863!16s%2Fg%2F11fny3zxk5?entry=ttu')
  }, [])

  const goToLinkedin = useCallback(() => {
    window.open('https://www.linkedin.com/company/laspi-ufrj')
  }, []);

  return (
    <div className="contato" data-scroll-to="contatoContainer">
      <div className="contato-infos">
        <img className="world-map-bg-icon" alt="" src="/world-mapbg@2x.png" />
        <div className="icons">
          <div className="fale-conosco">Fale Conosco</div>
          <div className="endereco">
            <img className="endereco-icon" alt="" src="/casa.svg" />
            <div className="endereco-texto">
              <span className="endereco-texto-txt">
                <p className="av-athos-da">Escola Politécnica da UFRJ</p>
                <p className="av-athos-da">
                  Av. Athos da Silveira Ramos, 149<br></br>Cidade
                  Universitária da UFRJ, Rio de Janeiro
                </p>
              </span>
            </div>
          </div>
          <div className="linkedin" onClick={goToLinkedin}>
            <img className="linkedin-icon" alt="" src="/linkedin.svg" />
            <div className="linkedin-text">LASPI - UFRJ</div>
          </div>
          <div className="phone">
            <img className="phone-icon" alt="" src="/phone.svg" />
            <div className="phone-number">+55 (21) 3938-0204</div>
          </div>
          <div className="email">
            <img className="email-icon" alt="" src="/email.svg" />
            <div className="email-texto">contato@laspi.ufrj.br</div>
          </div>
        </div>
      </div>
      <div className="map">
          {isLoaded ? 
            <GoogleMap zoom={13.5} center={laspiCords} mapContainerStyle={{width:'100%', height:'100%'}}
              onClick={onClickMap}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
            >
                  <Marker position={laspiCords} />
            </GoogleMap>

          : <div>Carregando</div>}
        </div>
    </div>
  );
});

export default FaleConoscoContainer;
