import { memo, useRef, useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./Slider3.css";

const Slider1 = memo(() => {
  const navigate = useNavigate();
  const carousel = useRef();
  const [width, setWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth + 45);
  }, [])

  const goLeft = useCallback(() => {
    setScrollLeft(0)
  })
  
  const goRight = useCallback(() => {
    setScrollLeft(1)
  })

  // rotas
  const onClickCartoes = useCallback(() => {
    navigate("/cartoes-criptograficos");
  }, [navigate])

  const onClickLeitoras = useCallback(() => {
    navigate("/leitoras");
  }, [navigate])

  const onClickHSM = useCallback(() => {
    navigate("/hsm");
  }, [navigate])

  const onClickTokens = useCallback(() => {
    navigate("/tokens-criptograficos");
  }, [navigate])

  const onClickREP = useCallback(() => {
    navigate("/rep");
  }, [navigate])

  return (
    <motion.div id="icp" className="slider1" ref={carousel}>
    <motion.div className="carousel"
      dragConstraints={{ left: -width, right: 0 }}
      dragElastic={0.1}
      dragTransistion={{ bounceStiffness: 600, bounceDamping: 10 }}
      style={{ transform: scrollLeft ?  'translateX(-'+width+'px) translateY(0px) translateZ(0px)': 'translateX(0px) translateY(0px) translateZ(0px)', transition: 'transform 0.5s ease 0s' }}
    >
      <div className="cartes-criptogrficos-wrapper" onClick={onClickCartoes}>
        <div className="texto-cards-escopo">CARTÕES CRIPTOGRÁFICOS</div>
      </div>
      <div className="leitora-de-cartes-criptogrfi-wrapper" onClick={onClickLeitoras}>
        <div className="texto-cards-escopo">
          LEITORAS DE CARTÕES CRIPTOGRÁFICOS
        </div>
      </div>
      <div className="tokens-criptogrficos-wrapper" onClick={onClickTokens}>
        <div className="texto-cards-escopo">TOKENS CRIPTOGRÁFICOS</div>
      </div>
      <div className="mdulos-de-segurana-criptogr-wrapper" onClick={onClickHSM}>
        <div className="texto-cards-escopo">
          MÓDULOS DE SEGURANÇA CRIPTOGRÁFICA
        </div>
      </div>
      <div className="registradores-eletrnicos-de-p-wrapper" onClick={onClickREP}>
        <div className="texto-cards-escopo">
          REGISTRADORES ELETRÔNICOS DE PONTO
        </div>
      </div>
    </motion.div>
    {scrollLeft === 0 ? <button className="button-right" onClick={goRight}>↪</button>
    : <button className="button-left" onClick={goLeft}>↩</button>}
    {scrollLeft === 0 ? <img className="dots-icon" src="/dots.svg"/> 
    : <img className="dots-icon" src="/dots1.svg"/>}
  </motion.div>
  );
});

export default Slider1;
