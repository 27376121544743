import { useCallback, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import ContactContainer from "../components/ContactContainer";
import FuelPumpContainer from "../components/Slider3";
import Slider1 from '../components/Slider1';
import Slider2 from "../components/Slider2";
import Footer from "../components/Footer";

import "./SoluesServios.css";
const SoluesServios = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let scroll = false;

  if (location.state) {
    scroll = location.state.opcao;
  }

  // rotas
  const onLogoLaspiImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onQUEMSOMOSTextClick = useCallback(() => {
    navigate("/quem-somos");
  }, [navigate]);

  const onACREDITAESTextClick = useCallback(() => {
    navigate("/acreditacoes");
  }, [navigate]);

  const onSOLUESSERVIOSClick = useCallback(() => {
    navigate("/solucoes-e-servicos");
  }, [navigate]);

  const onPginaInicialTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);


  // scroll ao vir dos ícones da pagina inicial ou das atuações
  const scrollDown = (() => {
    if (scroll != false) {

      if (scroll == "certificacoes"){
        const cert = document.querySelector("[id='ensaios']")
        if(cert) cert.scrollIntoView({ block: "start", behavior: "smooth" });
      }
      else if (scroll == "consultoria"){
        const consul = document.querySelector("[id='consultoria']")
        if(consul) consul.scrollIntoView({ block: "start", behavior: "smooth" });
      }
      else if (scroll == "p&d"){
        const ped = document.querySelector("[id='pesquisa']")
        if(ped) ped.scrollIntoView({ block: "start", behavior: "smooth" });
      }
      else if(scroll == "ICP"){
        const ensaios = document.querySelector("[id='icp']")
        if(ensaios) ensaios.scrollIntoView({ block: "start", behavior: "smooth" });
      }
      else if(scroll == "LEA"){
        const ensaios = document.querySelector("[id='lea']")
        if(ensaios) ensaios.scrollIntoView({ block: "start", behavior: "smooth" });
      }
      else if(scroll == "DIMEL"){
        const ensaios = document.querySelector(
          "[data-scroll-to='FuelPumpContainer']"
        );
        if(ensaios) ensaios.scrollIntoView({ block: "start", behavior: "smooth" });
      }

      scroll = false
      location.state.opcao = false;
    }
  });

  setInterval(scrollDown, 800);

  return (
    <div className="solues-servios">
      <img className="laspi-2-icon" alt="" src="/laspi-2@2x.png" />
      <div className="ensaios-tcnicos-atuando-neste-parent">
        <div id="ensaios"  className="leitora-de-cartes-container">
          <p className="ensaios-tcnicos">
            <b>
              <span style={{fontSize:32}}>Ensaios Técnicos</span>
            </b>
          </p>
          <p className="blank-line">
            <span>
              <b>&nbsp;</b>
            </span>
          </p>
          <p className="ensaios-tcnicos">
            <span>
              <span>
                Atuando neste mercado há 35 anos, o LASPI apresenta corpo
                técnico especializado e ambiente laboratorial altamente equipado
                e extremamente seguro para melhor lhe atender. Acreditado pelo
                INMETRO, CRL 1227, possuímos um rigoroso sistema de gestão
                orientado à norma ISO/IEC 17025 e desde 2017 assumimos a
                responsabilidade legal para atuar na avaliação técnica de
                diferentes equipamentos, instrumentos e softwares.
              </span>
            </span>
          </p>
          <p className="ensaios-tcnicos">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="ensaios-tcnicos">
            <span>
              <span>
                Somos, também, credenciados pelo Instituto Nacional de
                Tecnologia da Informação, nos termos das Resoluções ITI - ICP Brasil n.° 130 de 19/03/2021 e n.° 200 de 29/04/2021,
                como Laboratório de Ensaios e Auditoria – LEA, com amplas condições de planejar, analisar,
                avaliar, e emitir laudos de conformidade que embasam a tomada de
                decisão por parte do ITI quanto à homologação de Sistemas e
                Equipamentos de Certificação Digital padrão ICP-Brasil.
              </span>
            </span>
          </p>
        </div>
        <div className="leitora-de-cartes-container">
          <p className="ensaios-tcnicos">&nbsp;</p>
          <p className="ensaios-tcnicos">
            Especializado em análise de software realizamos tanto a análise
            dinâmica quanto estática de código-fonte contando com o auxílio de
            ferramentas automatizadas que dão suporte ao processo realizado por
            nossos especialistas, garantindo sempre máxima eficiência e
            agilidade. A realização de ambas as análises permite identificar
            tanto a construção algorítmica do código assim como sua
            funcionalidade e fluxo de dados possibilitando inferir com maior
            facilidade pontos críticos.
          </p>
          <p className="ensaios-tcnicos">&nbsp;</p>
          <p className="ensaios-tcnicos">
            Além de realizar a certificação de módulos criptográficos nos
            padrões ICP-Brasil, o LASPI também está acreditado para realizar a
            homologação de produtos de segurança que não se enquadrem em
            qualquer programa de certificação ICP-Brasil. Nossa equipe possui a
            experiência necessária para ajudá-lo no processo de homologação,
            atendendo desde a análise do produto, planejamento do ensaio,
            realização ou confecção de novos ensaios e emissão do relatório
            técnico junto ao ITI.
          </p>
        </div>
      </div>
      <div className="consultoria-a-habilidade-e-exp-parent">
        <div id="consultoria" className="leitora-de-cartes-container">
          <p className="consultoria">
            <b>Consultoria</b>
          </p>
          <p className="ensaios-tcnicos">&nbsp;</p>
          <p className="ensaios-tcnicos">
            A habilidade e expertise acumuladas por décadas de experiência no
            âmbito tecnológico, regulatório e jurídico, nos permitiu adquirir a
            competência necessária para ajudá-lo na maximização de valor ao seu
            negócio. Através de análise personalizada de suas necessidades, nós
            o ajudamos a identificar, planejar e implementar as melhores
            soluções aplicadas a sua realidade. Por meio desse canal, explore
            nossa gama de soluções, amplamente aplicáveis e específicas por área
            de atividade:
          </p>
          <p className="ensaios-tcnicos">&nbsp;</p>
          <ul className="lgpd-segurana-da-informao">
            <li className="lgpd">
              <span className="projetos-de-instrumentao">LGPD;</span>
            </li>
            <li className="lgpd">
              <span className="projetos-de-instrumentao">
                Segurança da Informação;
              </span>
            </li>
            <li className="lgpd">
              <span className="projetos-de-instrumentao">
                Projetos de Instrumentação Eletrônica;
              </span>
            </li>
            <li className="lgpd">
              <span className="projetos-de-instrumentao">Hardware;</span>
            </li>
            <li className="lgpd">
              <span className="projetos-de-instrumentao">
                Implantação de Sistema de Gestão da Qualidade;
              </span>
            </li>
            <li className="lgpd">
              <span className="projetos-de-instrumentao">
                Modelagem de Processos;
              </span>
            </li>
            <li>
              <span className="projetos-de-instrumentao">IoT.</span>
            </li>
          </ul>
        </div>
        <div id="pesquisa" className="leitora-de-cartes-container">
          <p className="consultoria">
            <b>{`Pesquisa & Desenvolvimento`}</b>
          </p>
          <p className="blank-line">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="ensaios-tcnicos">
            <span>
              <span>{`Com o mercado cada vez mais competitivo, é imprescindível que as empresas busquem ofertar um produto cada vez melhor, com o menor custo possível. Nesse sentido, o setor de `}</span>
              <span className="laspi">{`Pesquisa & Desenvolvimento do LASPI`}</span>
              <span>
                {" "}
                desempenha um papel fundamental para articular novidades e
                aperfeiçoar produtos ou serviços existentes.
              </span>
            </span>
          </p>
          <p className="ensaios-tcnicos">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="ensaios-tcnicos">
            <span>
              <span>
                Nossa equipe é capacitada para atender às necessidades
                específicas de nossos parceiros. Possui grande interação
                acadêmica e é composto por professores, doutores, mestres e
                engenheiros com qualificações acadêmicas e com larga experiência
                em projetos nas áreas de software, hardware, instrumentação,
                telecomunicações, bem como no exercício de perícias e ensaios
                técnicos em diversos segmentos de engenharia
              </span>
            </span>
          </p>
          <p className="ensaios-tcnicos">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="ensaios-tcnicos">
            <span>
              <span>
                Nossas principais linhas de pesquisa estão concentradas na área
                de
              </span>
              <span className="laspi">
                {" "}
                Automação Comercial, Energia, Geotecnia e Petróleo
              </span>
              <span className="do">.</span>
            </span>
          </p>
        </div>
      </div>
      <b className="escopo-de-certificao">Certificação de produtos</b>
      <b className="escopo-de-certificao1">Aprovação de modelo junto à DIMEL</b>
      <b className="escopo-de-certificao2">Homologação de produtos</b>
      <div className="barra-de-navegao">
        <div className="barra-de-navegao-child" />
        <div className="voc-est-aqui-container">
          <span>
            <span className="projetos-de-instrumentao">Você está aqui:</span>
            <span className="span1">{` `}</span>
          </span>
          <span className="span1">
            <span>/</span>
          </span>
        </div>
        <div className="solues-servios-container1">
          <span className="barra-span">{`/  `}</span>
          <span className="solues-servios1">{`Soluções & Serviços`}</span>
        </div>
        <div className="pgina-inicial1" onClick={onPginaInicialTextClick}>
          Página Inicial
        </div>
      </div>
      <ContactContainer
        coordinates="/logolaspi1@2x.png"
        frame1Position="absolute"
        frame1Width="calc(100% - 39.83px)"
        frame1Top="20px"
        frame1Right="19.83px"
        frame1Left="20px"
        onLogoLaspiImageClick={onLogoLaspiImageClick}
        onQUEMSOMOSTextClick={onQUEMSOMOSTextClick}
        onACREDITAESTextClick={onACREDITAESTextClick}
        onSOLUESSERVIOSClick={onSOLUESSERVIOSClick}
      />
      
      <Slider1/>
      <Slider2/>
      <FuelPumpContainer/>

      <Footer />

    </div>
  );
};

export default SoluesServios;
