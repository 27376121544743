import { memo, useRef, useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./Slider3.css";

const FuelPumpContainer = memo(() => {
  const navigate = useNavigate();
  const carousel = useRef();
  const [width, setWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth + 45);
  }, [])

  const goLeft = useCallback(() => {
    setScrollLeft(0)
  })
  
  const goRight = useCallback(() => {
    setScrollLeft(1)
  })

  // rotas
  const onClickBombas = useCallback(() => {
    navigate("/bombas-de-combustivel")
  }, [navigate]);
  const onClickVelocidade = useCallback(() => {
    navigate("/medidores-de-velocidade-automotivo")
  }, [navigate]);
  const onClickPesagem = useCallback(() => {
    navigate("/instrumentos-de-pesagem-rodoviario")
  }, [navigate]);
  const onClickAgua = useCallback(() => {
    navigate("/medidores-de-agua")
  }, [navigate]);
  const onClickEnergia = useCallback(() => {
    navigate("/medidores-de-energia")
  }, [navigate]);

  return (
    <motion.div className="slider3" ref={carousel} whileTap={{cursor: "grabbing"}}
    data-scroll-to="FuelPumpContainer"
    >
      <motion.div className="carousel2"
        dragConstraints={{ left: -width, right: 0 }}
        dragElastic={0.1}
        dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
        style={{ transform: scrollLeft ?  'translateX(-'+width+'px) translateY(0px) translateZ(0px)': 'translateX(0px) translateY(0px) translateZ(0px)', transition: 'transform 0.5s ease 0s' }}
      >
        <div className="bombas-medidoras-de-combustve-wrapper" onClick={onClickBombas}>
          <div className="texto-cards-escopo">
            BOMBAS MEDIDORAS DE COMBUSTÍVEL LÍQUIDO
          </div>
        </div>
        <div className="medidores-de-velocidade-de-ve-wrapper" onClick={onClickVelocidade}>
          <div className="texto-cards-escopo">
            MEDIDORES DE VELOCIDADE DE VEÍCULOS AUTOMOTIVOS
          </div>
        </div>
        <div className="instrumento-de-pesagem-automt-wrapper" onClick={onClickPesagem}>
          <div className="texto-cards-escopo">
            INSTRUMENTOS DE PESAGEM AUTOMÁTICO DE VEÍCULOS RODOVIÁRIOS EM
            MOVIMENTO
          </div>
        </div>
        <div className="medidor-eletrnico-de-gua-pot-wrapper" onClick={onClickAgua}>
          <div className="texto-cards-escopo">
            MEDIDORES ELETRÔNICOS DE ÁGUA POTÁVEL FRIA E ÁGUA QUENTE
          </div>
        </div>
        <div className="medidor-eletrnico-de-energia-wrapper" onClick={onClickEnergia}>
          <div className="texto-cards-escopo">
              MEDIDORES ELETRÔNICOS DE ENERGIA ELÉTRICA E SISTEMAS DISTRIBUÍDOS DE <br></br>MEDIÇÃO DE ENERGIA ELÉTRICA
           </div>
        </div>
      </motion.div>
      {scrollLeft === 0 ? <button className="button-right" onClick={goRight}>↪</button>
       : <button className="button-left" onClick={goLeft}>↩</button>}
      {scrollLeft === 0 ? <img className="dots-icon" src="/dots.svg"/> 
      : <img className="dots-icon" src="/dots1.svg"/>}
    </motion.div>
  );
});

export default FuelPumpContainer;
