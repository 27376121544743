import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./ContainerUFRJ.css";

const ContainerUFRJ = memo(() => {
  const navigate = useNavigate();

  const onCertificacoesContainerClick = useCallback(() => {
    navigate("/solucoes-e-servicos",
      {
        state: {
          opcao: "certificacoes",
        }
      }
    );
  }, [navigate]);

  const onConsultoriaContainerClick = useCallback(() => {
    navigate("/solucoes-e-servicos",
      {
        state: {
          opcao: "consultoria",
        }
      }
    );
  }, [navigate]);

  const onPDContainerClick = useCallback(() => {
    navigate("/solucoes-e-servicos",
      {
        state: {
          opcao: "p&d",
        }
      }
    );
  }, [navigate]);

  return (
    <div className="bloco-meio">
      <div className="h11">
        Laboratório da UFRJ que atua há mais de duas décadas junto à indústria
        para produzir tecnologia de ponta
      </div>
      <div className="areas-atuacao">
        <div className="certificacoes" onClick={onCertificacoesContainerClick}>
          <img
            className="certificacoes-icon"
            alt=""
            src="/certificacoesicon@2x.png"
          />
          <div className="certificaes">Certificações</div>
          <div className="acreditado-pelo-inmetro">
            Acreditado pelo Inmetro para realização de ensaios em equipamento
            eletrônicos e softwares
          </div>
        </div>
        <div className="consultoria1" onClick={onConsultoriaContainerClick}>
          <img className="pd-icon" alt="" src="/pdicon@2x.png" />
          <div className="consultoria2">Consultoria</div>
          <div className="solues-para-o">
            Soluções para o desenvolvimento tecnológico e segurança da
            informação
          </div>
        </div>
        <div className="pd" onClick={onPDContainerClick}>
          <img className="pesq-icon" alt="" src="/pesq@2x.png" />
          <div className="pesquisa-e-desenvolvimento">
            Pesquisa e Desenvolvimento
          </div>
          <div className="solues-para-o">
            Ampla experiência no desenvolvimento de tecnologias eletrônicas e de
            software
          </div>
        </div>
      </div>
    </div>
  );
});

export default ContainerUFRJ;
