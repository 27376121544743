import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ContactContainer from "../components/ContactContainer";
import "./MedidorDeGua.css";
import Footer from "../components/Footer";

const MedidorDeGua = () => {
  const navigate = useNavigate();

  const onLogoLaspiImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onQUEMSOMOSTextClick = useCallback(() => {
    navigate("/quem-somos");
  }, [navigate]);

  const onACREDITAESTextClick = useCallback(() => {
    navigate("/acreditacoes");
  }, [navigate]);

  const onSOLUESSERVIOSClick = useCallback(() => {
    navigate("/solucoes-e-servicos");
  }, [navigate]);

  const onSoluesServios1Click = useCallback(() => {
    navigate("/solucoes-e-servicos");
  }, [navigate]);

  const onClickVoltar = useCallback(() => {
    navigate("/solucoes-e-servicos",
    {
      state: {
        opcao: "DIMEL",
      }
    })
  }, [navigate])

  return (
    <div className="medidor-de-gua">
      <Footer />
      <div className="voltar-parent">
        <b className="voltar1" onClick={onClickVoltar}>{`< Voltar`}</b>
        <div className="leitora-de-cartes-container">
          <p className="medidor-eletrnico-de-gua-pot">
            <b>
              <span style={{fontSize: 'var(--font-size-13xl)'}}>Medidor Eletrônico de Água Potável Fria e Água Quente</span>
            </b>
          </p>
          <p className="blank-line">
            <span>
              <b>&nbsp;</b>
            </span>
          </p>
          <p className="medidor-eletrnico-de-gua-pot">
            <span>
              <span>
                Nossos ensaios são realizados seguindo rigorosamente as
                exigências explícitas no Regulamento Técnico Metrológico para
                Medidores de Água, para garantir a precisão e a confiabilidade
                dos resultados obtidos.
              </span>
            </span>
          </p>
          <p className="blank-line">
            <span>
              <b>&nbsp;</b>
            </span>
          </p>
          <p className="medidor-eletrnico-de-gua-pot">
            <span>
              <span>
                Se sua empresa busca obter a Aprovação de Modelo dos
                equipamentos de Metrologia Legal junto ao INMETRO, entre em
                contato conosco e descubra como podemos ajudar.<br></br><br></br>
                O LASPI é um laboratório de referência em ensaios técnicos,
                acreditado pelo INMETRO no sistema ISO/IEC 17025 desde janeiro
                de 2017. Nossa qualidade e confiabilidade são atestadas pela
                Rede Brasileira de Laboratórios de Ensaio (RBLE).
                Nossos serviços são essenciais para as empresas que desejam
                obter a certificação de seus equipamentos junto ao INMETRO. Com
                nossa capacidade técnica e expertise, fornecemos relatórios de
                alta qualidade que ajudam nossos clientes a atenderem aos
                requisitos necessários e, assim, obterem a certificação de seus
                produtos.
              </span>
            </span>
          </p>
        </div>
      </div>
      <ContactContainer
        coordinates="/logolaspi1@2x.png"
        frame1Position="absolute"
        frame1Width="calc(100% - 39.83px)"
        frame1Top="20px"
        frame1Right="19.83px"
        frame1Left="20px"
        propCursor="unset"
        onLogoLaspiImageClick={onLogoLaspiImageClick}
        onQUEMSOMOSTextClick={onQUEMSOMOSTextClick}
        onACREDITAESTextClick={onACREDITAESTextClick}
        onSOLUESSERVIOSClick={onSOLUESSERVIOSClick}
      />
      <div className="barra-de-navegao">
        <div className="barra-de-navegao-child" />
        <div className="voc-est-aqui-container">
          <span>
            <span className="voc-est-aqui7">Você está aqui:</span>
            <span className="span15">{` `}</span>
          </span>
          <span className="span15">
            <span>/</span>
          </span>
        </div>
        <div className="solues-servios-container">
          <span className="barra-span">{`/  `}</span>
          <span className="medidor-eletrnico-de1">
            Medidor Eletrônico de Água Potável Fria e Água Quente
          </span>
        </div>
        <div
          className="solues-servios2"
          onClick={onSoluesServios1Click}
        >{`Soluções & Serviços`}</div>
      </div>
    </div>
  );
};

export default MedidorDeGua;
