import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ContactContainer from "../components/ContactContainer";
import Footer from "../components/Footer";
import "./PginaQuemSomos.css";

const PginaQuemSomos = () => {
  const navigate = useNavigate();

  const onLogoLaspiImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onQUEMSOMOSTextClick = useCallback(() => {
    navigate("/quem-somos");
  }, [navigate]);

  const onACREDITAESTextClick = useCallback(() => {
    navigate("/acreditacoes");
  }, [navigate]);

  const onSOLUESSERVIOSClick = useCallback(() => {
    navigate("/solucoes-e-servicos");
  }, [navigate]);

  const onPginaInicialTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="pgina-quem-somos">
      <div className="barra-de-navegao">
        <div className="barra-de-navegao-child" />
        <div className="voc-est-aqui-container">
          <span>
            <span className="departamento-de-engenharia">Você está aqui:</span>
            <span className="span35">{` `}</span>
          </span>
          <span className="span35">
            <span>/</span>
          </span>
        </div>
        <div className="quem-somos">
          <span className="barra-span">{`/  `}</span>
          <span className="quem-somos1">Quem Somos</span>
        </div>
        <div className="pgina-inicial1" onClick={onPginaInicialTextClick}>
          Página Inicial
        </div>
      </div>
      <div className="sobre-o-laspi-o-laspi-labora-parent">
        <div className="leitora-de-cartes-container">
          <p className="sobre-o-laspi">
            <b>
              <span style={{fontSize:32}}>Sobre o LASPI</span>
            </b>
          </p>
          <p className="blank-line">
            <b>
              <span>&nbsp;</span>
            </b>
          </p>
          <p className="o-laspi-laboratrio-de-aplic">
            <span>
              <span className="o">{`O `}</span>
              <span className="laspi-laboratrio">
                LASPI – Laboratório de Aplicações Tecnológicas para o Setor
                Produtivo e Industrial
              </span>
              <span className="o">{` está localizado na `}</span>
              <span className="laspi-laboratrio">{`Escola Politécnica da Universidade Federal do Rio de Janeiro – UFRJ, `}</span>
              <span>{`e atua no desenvolvimento de tecnologia para levar soluções personalizadas e inovadoras que possam agregar valor e atender às necessidades de nossos parceiros e da sociedade. `}</span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="com-cerca-de-300-m-o-laborat">
            <span>
              <span>
                Com cerca de 300 m², o laboratório dispõe de modernas
                instalações e equipamentos que propiciam o desenvolvimento de
                soluções sofisticadas e ensaios técnicos de qualidade. Seu
                ambiente é dividido em cinco níveis de segurança, claramente
                delimitados por sistema de controle de acesso, que permite uma
                adequada segurança física laboratorial.
              </span>
            </span>
          </p>
        </div>
        <div className="leitora-de-cartes-container">
          <p className="sobre-o-laspi1">
            <b>
              <span style={{fontSize:32 }}>Nossa História</span>
            </b>
          </p>
          <p className="blank-line">
            <b>
              <span>{` `}</span>
            </b>
          </p>
          <p className="o-laspi-laboratrio-de-aplic">
            <span className="o">
              <span className="o2">O</span>
            </span>
            <span>
              <span className="o">{` `}</span>
              <span className="laspi-laboratrio">
                Laboratório de Aplicações Tecnológicas para o Setor Produtivo
              </span>
              <span className="o">{` é um laboratório do `}</span>
              <span className="departamento-de-engenharia">
                Departamento de Engenharia Eletrônica da Escola Politécnica da
                Universidade Federal do Rio de Janeiro
              </span>
              <span className="o"> que presta serviços de </span>
              <span className="departamento-de-engenharia">{`Ensaios Técnicos em equipamentos eletrônicos, P&D e Consultoria.`}</span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span className="departamento-de-engenharia">&nbsp;</span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span className="o">{`Desde que foi `}</span>
              <span className="laspi-laboratrio">fundado em 1984</span>
              <span className="o">{`, o `}</span>
              <span className="laspi-laboratrio">LASPI</span>
              <span className="o">{` buscou desenvolver tecnologia de ponta para atuar na demanda nacional e internacional. Já nos seus primeiros anos, realizou diversos projetos na área de `}</span>
              <span className="laspi-laboratrio">
                Automação Comercial, Energia, Geotecnia e Petróleo
              </span>
              <span>
                {" "}
                , tornando-se um importante braço dentro da UFRJ.
              </span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span>{`Nos anos seguintes, aumentou seu negócio para atuar em um novo segmento e expandiu sua atuação para a área de Consultoria. Em sua primeira acreditação pelo `}</span>
              <span className="laspi-laboratrio">Inmetro</span>
              <span>
                , o laboratório passou a atuar na realização de ensaios técnicos
                em Registradores Eletrônicos de Ponto.
              </span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span>{`Com uma forte visão de negócio, o LASPI continuou expandindo seu portfólio e, em 2017, foi um dos primeiros laboratórios a ser acreditado pelo Inmetro a realizar ensaios técnicos no âmbito da `}</span>
              <span className="laspi-laboratrio">ICP Brasil</span>
              <span>.</span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span>
              Atualmente, o laboratório possui um vasto escopo para ensaios de software que englobam, também, Bombas Medidoras de Combustíveis Líquidos, Medidores de Velocidade de Veículos Automotores, Medidores Eletrônicos de Energia Elétrica, Medidores de Volume de Água Potável ou Água Quente, Instrumentos de Pesagem Automáticos de Veículos Rodoviários em Movimento.
              </span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span>{`Assim, o `}</span>
              <span className="laspi-laboratrio">{`LASPI `}</span>
              <span className="o">{`acumula uma sólida reputação na `}</span>
              <span className="laspi-laboratrio">
                Universidade Federal do Rio de Janeiro
              </span>
              <span>
                , constituindo um grupo de excelência no desenvolvimento de
                sistemas de instrumentação eletrônica com inteligência
                incorporada, na obtenção de patentes e na otimização de
                processos.
              </span>
            </span>
          </p>
          <p className="sobre-o-laspi">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="com-cerca-de-300-m-o-laborat">
            <span>
              <span>{`Ao longo de mais de 35 anos, o `}</span>
              <span className="laspi-laboratrio">{`LASPI `}</span>
              <span>
                se consolidou através de um código moral, ético e imparcial
                firmados desde sua origem e que se coloca à disposição das
                regulamentações e do mercado brasileiro para trazer soluções
                corretas e com impacto positivo para a população.
              </span>
            </span>
          </p>
          <br></br><br></br>
          <a href="politica-laspi.pdf" target="_blank" title="Read PDF">Acesse 
          nossa Declaração de Princípios, Políticas e Objetivos do LASPI</a>
        </div>
      </div>
      <ContactContainer
        coordinates="/logolaspi1@2x.png"
        frame1Position="absolute"
        frame1Width="calc(100% - 39.83px)"
        frame1Top="20px"
        frame1Right="19.83px"
        frame1Left="20px"
        onLogoLaspiImageClick={onLogoLaspiImageClick}
        onQUEMSOMOSTextClick={onQUEMSOMOSTextClick}
        onACREDITAESTextClick={onACREDITAESTextClick}
        onSOLUESSERVIOSClick={onSOLUESSERVIOSClick}
      />
      
      <Footer />
    </div>
  );
};

export default PginaQuemSomos;
