import { memo, useCallback } from "react";
import "./PartnersContainer.css";
const PartnersContainer = memo(() => {
  const onCapesClick = useCallback(() => {
    window.open("https://www.gov.br/capes/pt-br");
  }, []);

  const onCnpqClick = useCallback(() => {
    window.open("https://www.gov.br/cnpq/pt-br");
  }, []);

  const onFaperjClick = useCallback(() => {
    window.open("https://www.faperj.br/");
  }, []);

  const onFinepContainerClick = useCallback(() => {
    window.open("http://www.finep.gov.br/");
  }, []);

  const onItiClick = useCallback(() => {
    window.open("https://www.gov.br/iti/pt-br");
  }, []);

  const onInmetroClick = useCallback(() => {
    window.open("http://www.inmetro.gov.br/");
  }, []);

  const onUfrjClick = useCallback(() => {
    window.open("https://ufrj.br/");
  }, []);

  const onPoliClick = useCallback(() => {
    window.open("https://poli.ufrj.br/");
  }, []);

  const onCoppetecClick = useCallback(() => {
    window.open("http://www.coppetec.coppe.ufrj.br/site/");
  }, []);

  return (
    <div className="partners">
      <div className="partners-2">
        <img
          className="capes-icon"
          alt=""
          src="/capes.svg"
          onClick={onCapesClick}
        />
        <img
          className="capes-icon"
          alt=""
          src="/cnpq.svg"
          onClick={onCnpqClick}
        />
        <img
          className="capes-icon"
          alt=""
          src="/faperj.svg"
          onClick={onFaperjClick}
        />
        <div className="finep" onClick={onFinepContainerClick}>
          <img
            className="marca-rgb-2023-1-icon"
            alt=""
            src="/MARCA_RGB_2023.svg"
          />
        </div>
        <img
          className="coppetec-icon"
          alt=""
          src="/coppetec.jpg"
          onClick={onCoppetecClick}
        />
      </div>
      <div className="partners-1">
        <img
          className="capes-icon"
          alt=""
          src="/ufrj.svg"
          onClick={onUfrjClick}
        />
        <img
          className="capes-icon"
          alt=""
          src="/inmetro.svg"
          onClick={onInmetroClick}
        />
        <img
          className="capes-icon"
          alt=""
          src="/iti.svg"
          onClick={onItiClick}
        />
        <img
          className="capes-icon2"
          alt=""
          src="/Marca_Principal_P_B.svg"
          onClick={onPoliClick}
        />
      </div>
    </div>
  );
});

export default PartnersContainer;
