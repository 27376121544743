import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FaleConoscoContainer from "../components/FaleConoscoContainer";
import PartnersContainer from "../components/PartnersContainer";
import InitialImageContainer from "../components/InitialImageContainer";
import ContainerUFRJ from "../components/ContainerUFRJ";
import ContactContainer from "../components/ContactContainer";
import "./PginaInicial.css";

const PginaInicial = () => {
  const navigate = useNavigate();
  const location = useLocation();
  if (location.state) {
    scroll = location.state.scroll;
  }

  const onLogoLaspiImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onQUEMSOMOSTextClick = useCallback(() => {
    navigate("/quem-somos");
  }, [navigate]);

  const onACREDITAESTextClick = useCallback(() => {
    navigate("/acreditacoes");
  }, [navigate]);

  const onSOLUESSERVIOSClick = useCallback(() => {
    navigate("/solucoes-e-servicos");
  }, [navigate]);

  const onCONTATOTextClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contatoContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const scrollDown = (() => {
    
    if (scroll === true) {
      scroll = false

      const anchor = document.querySelector(
        "[data-scroll-to='contatoContainer']"
      );
      if (anchor) {
        anchor.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  });

  setInterval(scrollDown, 800);

  return (
    <div className="pgina-inicial">
      <FaleConoscoContainer />
      <PartnersContainer />
      <div className="fotos">
        <img className="image2-icon" alt="" src="/image2@2x.png" />
        <img className="image3-icon" alt="" src="/image3@2x.png" />
        <img className="image1-icon" alt="" src="/image1@2x.png" />
        <img className="image5-icon" alt="" src="/image5@2x.png" />
        <img className="image4-icon" alt="" src="/image4@2x.png" />
      </div>
      <InitialImageContainer />
      <ContainerUFRJ />
      <ContactContainer
        coordinates="/logolaspi1@2x.png"
        onLogoLaspiImageClick={onLogoLaspiImageClick}
        onQUEMSOMOSTextClick={onQUEMSOMOSTextClick}
        onACREDITAESTextClick={onACREDITAESTextClick}
        onSOLUESSERVIOSClick={onSOLUESSERVIOSClick}
        onCONTATOTextClick={onCONTATOTextClick}
      />
    </div>
  );
};

export default PginaInicial;
